import React, { useState } from "react";
import { testimonial } from "../data/Data";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../css/style.css'
import CommonHeading from "../common/CommonHeading";

export default function Sliders() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000, // Increase speed for a smoother transition
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Slide every 2 seconds
    cssEase: "linear" 
  };
  const [isExpanded, setIsExpanded] = useState(testimonial.map(() => false));

  const renderText = (text, isExpanded) => {
    const limit = 310;
    if (text.length <= limit || isExpanded) return text;
    return text.substring(0, limit) + '...';
  };
  return (
    <>
    <div className="heading-container">
  <h1>Guest Reviews</h1>
</div>

      <div
        className="container-xxl testimonial my-5 py-5 wow zoomIn"
        data-wow-delay="0.1s"
      >
        <div className="container">
      
          <div className="owl-carousel testimonial-carousel py-5">
            <Slider {...settings}>
              {testimonial.map((item, key) => (
                <div
                  key={key}
                  className="testimonial-item position-relative owl-carousel2 bg-white rounded overflow-hidden"
                >
                  <p style={{ textAlign: "justify" }}>{renderText(item.description, isExpanded[key])}</p>
                  <div className="d-flex align-items-center">
                    <img
                      className="img-fluid flex-shrink-0 rounded"
                      src={item.img}
                      style={{ width: "45px", height: "45px" }}
                    />
                    <div className="ps-3">
                      <h6 className="fw-bold mb-1">{item.name}</h6>
                      <small>{item.profession}</small>
                    </div>
                  </div>
                  {item.icon}
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}
